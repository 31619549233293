import React from 'react';
import Verify from '../components/Verify';

const Verification = () => {

  return (
    <div>
        <>
        <section>
            <div className="footerish">
                <Verify />
            </div>
        </section>
        </>
    </div>
  )
}

export default Verification